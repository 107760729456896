import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { Flex } from '@rebass/grid/emotion'
import { LocaleContext } from '../i18n'

const NotFoundPage = ({ pageContext }) => (
  <IndexLayout pageContext={pageContext}>
    <Page>
      <Flex justifyContent="center" pt={4}>
        <LocaleContext.Consumer>{({ t }) =>
          <h1>{t('error.page-not-found')}</h1>
        }</LocaleContext.Consumer>
      </Flex>
    </Page>
  </IndexLayout>

)

export default NotFoundPage
